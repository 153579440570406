import styles from "./ContactUsForm.module.scss";
import Button from "../../../../components/Button/Button";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Dialog from "../../../../components/Dialog/Dialog";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { Form, Field } from "react-final-form";
import classnames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";

const isValidEmail = (value) => {
  return /\S+@\S+\.\S+/.test(value) ? undefined : "Invalid Email";
};

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "required";
  }
  if (!values.lastName) {
    errors.lastName = "required";
  }
  if (!values.email) {
    errors.email = "required";
  }
  if (isValidEmail(values.email)) {
    errors.email = "invalid_email";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "required";
  }
  if (!values.message) {
    errors.message = "required";
  }

  return errors;
};

export default function ContactUsForm() {
  const [hasSendError, setSendError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async (values, form) => {
    const { status } = await fetch("https://jiwctakkrh.execute-api.eu-north-1.amazonaws.com/dev/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .catch((errors) => {
        console.log("send error", errors);
        setSendError(true);
      });

    if (status) {
      if (status === "success") {
        setSendError(false);
        Object.keys(values).forEach((key) => {
          form.change(key, undefined);
          form.resetFieldState(key);
        });
      } else {
        setSendError(true);
      }
    } else {
      setSendError(true);
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={(values) => handleSubmit(values, form)}>
            <div className={styles.row}>
              <div className={styles.col}>
                <Field name="firstName">
                  {({ input, meta }) => (
                    <div>
                      <label className={styles.label} htmlFor="firstName">
                        first name
                      </label>
                      <input
                        {...input}
                        id="firstName"
                        component="input"
                        type="text"
                        className={classnames(styles.input, {
                          [styles["input-error"]]: (meta.error || meta.submitError) && meta.touched,
                        })}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className={styles.col}>
                <Field name="lastName">
                  {({ input, meta }) => (
                    <div>
                      <label className={styles.label} htmlFor="lastName">
                        last name
                      </label>
                      <input
                        {...input}
                        id="lastName"
                        component="input"
                        type="text"
                        className={classnames(styles.input, {
                          [styles["input-error"]]: (meta.error || meta.submitError) && meta.touched,
                        })}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <label className={styles.label} htmlFor="email">
                        email
                      </label>
                      <input
                        {...input}
                        id="email"
                        component="input"
                        type="email"
                        className={classnames(styles.input, {
                          [styles["input-error"]]: (meta.error || meta.submitError) && meta.touched,
                        })}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className={styles.col}>
                <Field name="phoneNumber">
                  {({ input, meta }) => (
                    <div>
                      <label className={styles.label} htmlFor="phoneNumber">
                        phone number
                      </label>
                      <input
                        {...input}
                        id="phoneNumber"
                        component="input"
                        type="text"
                        className={classnames(styles.input, {
                          [styles["input-error"]]: (meta.error || meta.submitError) && meta.touched,
                        })}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div>
              <Field name="message">
                {({ input, meta }) => (
                  <div>
                    <label className={styles.label} htmlFor="message">
                      your message
                    </label>
                    <textarea
                      {...input}
                      id="message"
                      component="textarea"
                      type="textarea"
                      className={classnames(styles.textarea, {
                        [styles["input-error"]]: (meta.error || meta.submitError) && meta.touched,
                      })}
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className={styles["checkbox-wrapper"]}>
              <Field validate={(value) => (value ? undefined : "required")} name="privacyPolicy">
                {(props) => (
                  <>
                    <Checkbox
                      value={props.input.value}
                      onChange={props.input.onChange}
                      isInvalid={(props.meta.error || props.meta.submitError) && props.meta.touched}
                    />
                    <label
                      onClick={() => props.input.onChange(!props.input.value)}
                      className={classnames(styles.label, styles["label-inline"])}
                      htmlFor="privacyPolicy"
                    >
                      I have read the{" "}
                      <Link target="_blank" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </label>
                  </>
                )}
              </Field>
            </div>
            <div>
              <Button
                className={classnames(styles["submit-btn"], { [styles.submitting]: submitting })}
                type="submit"
                disabled={submitting || !values?.privacyPolicy}
              >
                {submitting ? (
                  <>
                    <span>Sending.. </span>
                    <LoadingSpinner size="32" className={styles["loading-spinner"]} color="#fff" />
                  </>
                ) : (
                  <span>Send</span>
                )}
              </Button>
            </div>
          </form>
        )}
      />
      <Dialog isOpen={isOpen} onClose={closeModal}>
        {hasSendError ? (
          <>
            <div className={styles["dialog-icon"]}>
              <img src="/assets/images/icons/error.svg" alt="icon-error" />
            </div>
            <h3 className={styles["dialog-title"]}>Your message was not sent</h3>
            <p>Please try again later</p>
          </>
        ) : (
          <>
            <div className={styles["dialog-icon"]}>
              <img src="/assets/images/icons/checkmark.svg" alt="icon-checkmark" />
            </div>
            <h3 className={styles["dialog-title"]}>Your message has been sent</h3>
            <p>We will contact you shortly</p>
          </>
        )}

        <Button onClick={closeModal}>Go back</Button>
      </Dialog>
    </>
  );
}
