import styles from "./Testimonial.module.scss";
import t from "../../localizations.json";

export default function Testimonial() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h5 className={styles.text}>{t.pages.home.sections.testimonial.text}</h5>
            <div className={styles.author}>
              <div className={styles.name}>{t.pages.home.sections.testimonial.author}</div>
              <div className={styles.profession}>{t.pages.home.sections.testimonial.authorProfession}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
