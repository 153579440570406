import styles from "./Dialog.module.scss";
import { Fragment } from "react";
import { Dialog as DialogComponent, Transition } from "@headlessui/react";

export default function Dialog({ isOpen, onClose, children }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <DialogComponent as="div" className={styles.dialog} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={styles.backdrop} />
        </Transition.Child>

        <div className={styles["dialog-wrapper"]}>
          <div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogComponent.Panel className={styles["dialog-panel"]}>
                <button onClick={onClose} className={styles["close-button"]}>
                  <img className={styles["close-icon"]} src="./assets/images/icons/close.svg" alt="close-icon" />
                </button>
                {children}
              </DialogComponent.Panel>
            </Transition.Child>
          </div>
        </div>
      </DialogComponent>
    </Transition>
  );
}
