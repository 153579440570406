import Hero from "../../components/Hero/Hero";
import WhatIsOurGoal from "./sections/WhatIsOurGoal/WhatIsOurGoal";
import PartneringWithUs from "./sections/PartneringWithUs/PartneringWithUs";
import NumberOne from "./sections/NumberOne/NumberOne";
import Testimonial from "../../components/Testimonial/Testimonial";
import Partners from "./sections/Partners/Partners";
import Layout from "../../layouts/Layout";
import { HOME_LAYOUT } from "../../utils/constants";

export default function Home() {
  return (
    <Layout layout={HOME_LAYOUT}>
      <Hero />
      <WhatIsOurGoal />
      <Testimonial />
      <Partners />
      <PartneringWithUs />
      <NumberOne />
    </Layout>
  );
}
