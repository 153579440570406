import Layout from "../../layouts/Layout";
import Contact from "./sections/Contact/Contact";
import { COMMON_LAYOUT } from "../../utils/constants";

export default function ContactUs() {
  return (
    <Layout layout={COMMON_LAYOUT}>
      <div className="container-local">
        <Contact />
      </div>
    </Layout>
  );
}
