import styles from "./NumberOne.module.scss";
import Title from "../../../../components/Title/Title";
import t from "../../../../localizations.json";
import classnames from "classnames";

export default function NumberOne() {
  return (
    <div className={styles.container}>
      <div className="container-local">
        <div className={styles.row}>
          <div className={styles.col}>
            <Title subtitle={t.pages.home.sections.numberOne.subtitle}>{t.pages.home.sections.numberOne.title}</Title>
            <div className={styles.paragraph}>
              <p dangerouslySetInnerHTML={{ __html: t.pages.home.sections.numberOne.paragraph1 }} />
              <p dangerouslySetInnerHTML={{ __html: t.pages.home.sections.numberOne.paragraph2 }} />
            </div>
          </div>
          <div className={classnames(styles.col, styles["col-right"])}>
            <img className={styles.image} src="./assets/images/number-one.png" alt="what-is-our-goal" />
          </div>
        </div>
      </div>
    </div>
  );
}
