import Home from "./pages/Home/Home";
import OurProcess from "./pages/OurProcess/OurProcess";
import ContactUs from "./pages/ContactUs/ContactUs";
import Policy from "./pages/Policy/Policy";
import NotFound from "./pages/NotFound/NotFound";
import { Route, Routes } from "react-router-dom";

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/our-process" element={<OurProcess />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacy-policy" element={<Policy type="PRIVACY" />} />
      <Route path="/cookies-policy" element={<Policy type="COOKIES" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
