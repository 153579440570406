import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { COMMON_LAYOUT, HOME_LAYOUT } from "../../utils/constants";
import styles from "./Header.module.scss";
import classnames from "classnames";
import t from "../../localizations.json";

function Header({ layout }) {
  const [isMenuNavOpen, setMenuNavOpen] = useState(false);
  const navRef = useRef(null);
  const getLogoImgName = useMemo(() => {
    return layout === COMMON_LAYOUT ? "logo-dark" : "logo";
  }, [layout]);

  const getMenuImgName = useMemo(() => {
    return layout === COMMON_LAYOUT ? "menu-black" : "menu-white";
  }, [layout]);

  const toggleMenuNav = useCallback(() => {
    setMenuNavOpen(!isMenuNavOpen);
  }, [isMenuNavOpen]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!navRef.current.contains(e.target) && isMenuNavOpen) {
        setMenuNavOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuNavOpen]);

  return (
    <header
      className={classnames(styles.header, {
        [styles["common-layout"]]: layout === COMMON_LAYOUT,
        [styles["home-layout"]]: layout === HOME_LAYOUT,
      })}
    >
      <div className="container-local">
        <div className={styles.row}>
          <div className="col">
            <Link to="/">
              <img src={`./assets/images/${getLogoImgName}.svg`} className={styles.logo} alt="logo" />
            </Link>
          </div>
          <div className="col">
            <button onClick={toggleMenuNav} className={styles["menu-button"]}>
              <img
                className={styles["menu-icon"]}
                src={`./assets/images/icons/${getMenuImgName}.svg`}
                alt="menu-icon"
              />
            </button>
            {isMenuNavOpen && (
              <button onClick={toggleMenuNav} className={styles["close-button"]}>
                <img className={styles["close-icon"]} src={`./assets/images/icons/close.svg`} alt="close-icon" />
              </button>
            )}
            <nav ref={navRef} className={classnames(styles.nav, { [styles["nav-hidden"]]: !isMenuNavOpen })}>
              <ul>
                <li>
                  <Link to="/">{t.header.nav.links.home}</Link>
                </li>
                <li>
                  <Link to="/our-process">{t.header.nav.links.ourProcess}</Link>
                </li>
                <li>
                  <Link to="/contact-us">{t.header.nav.links.contactUs}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
