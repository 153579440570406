import styles from "./WeFitAnyPartner.module.scss";
import Title from "../../../../components/Title/Title";
import Box from "../../../../components/Box/Box";
import t from "../../../../localizations.json";

export default function WeFitAnyPartner() {
  return (
    <div className={styles.container}>
      <Title className={styles.title} subtitle="Flexible">
        {t.pages.ourProcess.sections.weFitAnyPartner.title}
      </Title>
      <div className={styles.row}>
        <div className={styles.col}>
          <Box
            icon="gear"
            title={t.pages.ourProcess.sections.weFitAnyPartner.box1.title}
            text={t.pages.ourProcess.sections.weFitAnyPartner.box1.text}
            className={styles.box}
          />
        </div>
        <div className={styles.col}>
          <Box
            icon="circles"
            title={t.pages.ourProcess.sections.weFitAnyPartner.box2.title}
            text={t.pages.ourProcess.sections.weFitAnyPartner.box2.text}
            className={styles.box}
          />
        </div>
        <div className={styles.col}>
          <Box
            icon="gauge"
            title={t.pages.ourProcess.sections.weFitAnyPartner.box3.title}
            text={t.pages.ourProcess.sections.weFitAnyPartner.box3.text}
            className={styles.box}
          />
        </div>
      </div>
    </div>
  );
}
