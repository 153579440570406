import styles from "./WhatIsOurGoal.module.scss";
import Title from "../../../../components/Title/Title";
import Button from "../../../../components/Button/Button";
import t from "../../../../localizations.json";
import classnames from "classnames";

export default function WhatIsOurGoal() {
  return (
    <div className={styles.container}>
      <div className="container-local">
        <div className={styles.row}>
          <div className={styles.col}>
            <Title>{t.pages.home.sections.whatIsOurGoal.title}</Title>
            <div className={styles.paragraph}>
              <p dangerouslySetInnerHTML={{ __html: t.pages.home.sections.whatIsOurGoal.paragraph1 }} />
              <p dangerouslySetInnerHTML={{ __html: t.pages.home.sections.whatIsOurGoal.paragraph2 }} />
            </div>
            <Button to="/contact-us" className={styles.button}>
              {t.pages.home.sections.whatIsOurGoal.button}
            </Button>
          </div>
          <div className={classnames(styles.col, styles["col-right"])}>
            <img className={styles.image} src="./assets/images/what-is-our-goal.png" alt="what-is-our-goal" />
          </div>
        </div>
      </div>
    </div>
  );
}
