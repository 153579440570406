import styles from "./Checkbox.module.scss";
import classnames from "classnames";

const Checkbox = ({ value, onChange, isInvalid }) => {
  const onClick = () => {
    onChange(!value);
  };

  return (
    <div onClick={onClick} className={styles.wrapper}>
      <div className={classnames(styles.checkbox, { [styles.checked]: value, [styles.invalid]: isInvalid })}>
        {value && <img className={styles.icon} src="/assets/images/icons/check.svg" alt="check" />}
      </div>
    </div>
  );
};

export default Checkbox;
