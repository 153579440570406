import styles from "./PartneringWithUs.module.scss";
import Title from "../../../../components/Title/Title";
import Button from "../../../../components/Button/Button";
import Box from "../../../../components/Box/Box";
import t from "../../../../localizations.json";
import classnames from "classnames";

export default function PartneringWithUs() {
  return (
    <div className={styles.container}>
      <div className="container-local">
        <div className={styles.header}>
          <Title subtitle={t.pages.home.sections.partneringWithUs.subtitle}>
            {t.pages.home.sections.partneringWithUs.title}
          </Title>
          <Button to="/our-process" className={styles["title-button"]}>
            {t.pages.home.sections.partneringWithUs.button}
          </Button>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Box
              icon="arrows"
              title={t.pages.home.sections.partneringWithUs.box1.title}
              text={t.pages.home.sections.partneringWithUs.box1.text}
              className={styles["box-big"]}
              enableHtml
            />
          </div>
          <div className={styles.col}>
            <Box
              icon="risk-free"
              title={t.pages.home.sections.partneringWithUs.box2.title}
              text={t.pages.home.sections.partneringWithUs.box2.text}
              className={classnames(styles.box, styles["box-risk"])}
            />
            <Box
              icon="checkmark"
              title={t.pages.home.sections.partneringWithUs.box3.title}
              text={t.pages.home.sections.partneringWithUs.box3.text}
              className={styles.box}
            />
          </div>
        </div>
        <Button to="/our-process" className={styles.button}>
          {t.pages.home.sections.partneringWithUs.button}
        </Button>
      </div>
    </div>
  );
}
