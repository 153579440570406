import Layout from "../../layouts/Layout";
import { COMMON_LAYOUT } from "../../utils/constants";
import Hero from "./sections/Hero/Hero";
import Benefits from "./sections/Benefits/Benefits";
import WeFitAnyPartner from "./sections/WeFitAnyPartner/WeFitAnyPartner";

export default function OurProcess() {
  return (
    <Layout layout={COMMON_LAYOUT}>
      <div className="container-local">
        <Hero />
        <Benefits />
        <WeFitAnyPartner />
      </div>
    </Layout>
  );
}
