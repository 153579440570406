import styles from "./Footer.module.scss";
import t from "../../localizations.json";
import classnames from "classnames";
import { Link } from "react-router-dom";

const Contacts = () => {
  return (
    <div className={styles.contacts}>
      <div>
        <a className={styles["linked-in"]} href="https://www.linkedin.com/company/confindo-group/" target="_blank">
          {t.footer.links.linkedIn}
        </a>
      </div>
      <div>
        <a href={`tel:${t.footer.links.phoneNumber}`} target="_blank">
          {t.footer.links.phoneNumber}
        </a>
      </div>
      <div>
        <a href={`mailto:${t.footer.links.email}`} target="_blank">
          {t.footer.links.email}
        </a>
      </div>
    </div>
  );
};

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container-local">
        <div className="row">
          <div className="col">
            <img src="./assets/images/logo.svg" className={styles.logo} alt="logo" />
          </div>
        </div>
        <div className={classnames(styles.offices, "row")}>
          <div className="col">
            <div className={styles["office-title"]}>{t.footer.offices.office1.cityCountry}</div>
            <div>{t.footer.offices.office1.street}</div>
            <div>
              <a href={`mailto:${t.footer.offices.office1.email}`} target="_blank">
                {t.footer.offices.office1.email}
              </a>
            </div>
          </div>
          <div className="col">
            <div className={styles["office-title"]}>{t.footer.offices.office2.cityCountry}</div>
            <div>{t.footer.offices.office2.street}</div>
            <div>
              <a href={`mailto:${t.footer.offices.office2.email}`} target="_blank">
                {t.footer.offices.office2.email}
              </a>
            </div>
          </div>
          <div className="col">
            <div className={styles["office-title"]}>{t.footer.offices.office3.cityCountry}</div>
            <div>{t.footer.offices.office3.street}</div>
            <div>
              <a href={`mailto:${t.footer.offices.office3.email}`} target="_blank">
                {t.footer.offices.office3.email}
              </a>
            </div>
          </div>
          <div className="col">
            <div className={styles["office-title"]}>{t.footer.offices.office4.cityCountry}</div>
            <div>{t.footer.offices.office4.street}</div>
            <div>
              <a href={`mailto:${t.footer.offices.office4.email}`} target="_blank">
                {t.footer.offices.office4.email}
              </a>
            </div>
          </div>
          <div className={styles["contacts-col"]}>
            <Contacts />
          </div>
        </div>
        <div className={classnames(styles["footer-info"], "row")}>
          <div className="col">
            <div className={styles["footer-info-left"]}>
              <span>
                © {new Date().getFullYear()}
                {t.footer.rightsReserved}
              </span>
              <Link to="/privacy-policy">{t.footer.links.privacyPolicy}</Link>
              <Link to="/cookies-policy">{t.footer.links.cookies}</Link>
            </div>
          </div>
          <div className={styles["footer-info-right"]}>
            <Contacts />
          </div>
        </div>
      </div>
    </footer>
  );
}
