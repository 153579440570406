import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export default function Layout({ children, layout }) {
  return (
    <>
      <Header layout={layout} />
      {children}
      <Footer />
    </>
  );
}
