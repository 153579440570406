import styles from "./Button.module.scss";
import classnames from "classnames";
import { Link } from "react-router-dom";

export default function Button({ children, className: classNameProp, to, onClick, ...props }) {
  const Component = to ? Link : "button";

  return (
    <Component onClick={onClick} to={to} className={classnames(styles.button, classNameProp)} {...props}>
      {children}
    </Component>
  );
}
