import styles from "./Contact.module.scss";
import Title from "../../../../components/Title/Title";
import ContactUsForm from "../ContactUsForm/ContactUsForm";
import classnames from "classnames";
import t from "../../../../localizations.json";

export default function Contact() {
  const renderParagraphs = () => (
    <>
      <h3 className={styles["contacts-title"]}>{t.pages.contactUs.contacts.title}</h3>
      <div className={styles["contacts-address"]}>{t.pages.contactUs.contacts.address}</div>
      <div className={styles["contacts-country"]}>{t.pages.contactUs.contacts.country}</div>
      <div
        className={styles["contacts-email"]}
        dangerouslySetInnerHTML={{ __html: t.pages.contactUs.contacts.email }}
      />
      <div
        className={styles["contacts-phone-number"]}
        dangerouslySetInnerHTML={{ __html: t.pages.contactUs.contacts.phoneNumber }}
      />
      <p>{t.pages.contactUs.paragraph2}</p>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={classnames(styles.col, styles["col-left"])}>
          <div className={styles.container}>
            <Title className={styles.title} tag="h1">
              Contact Us
            </Title>
            <div className={styles["paragraph-top"]}>{renderParagraphs()}</div>
            <ContactUsForm />
          </div>
        </div>
        <div className={classnames(styles.col, styles["col-right"])}>
          <div className={styles.paragraph}>{renderParagraphs()}</div>
        </div>
      </div>
    </div>
  );
}
