import styles from "./Partners.module.scss";

export default function Partners() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <img className={styles.lenders} src="./assets/images/partners/lenders.png" alt="lenders-logo" />
        <img className={styles.citadele} src="./assets/images/partners/citadele.png" alt="citadele-bank-logo" />
        <img className={styles.tfbank} src="./assets/images/partners/tf-bank.png" alt="tf-bank-logo" />
        <img className={styles.seb} src="./assets/images/partners/seb.png" alt="seb-logo" />
        <img className={styles.bfjord} src="./assets/images/partners/bfjord.png" alt="bfjord-logo" />
        <img className={styles.luminor} src="./assets/images/partners/luminor.png" alt="luminor-logo" />
      </div>
    </div>
  );
}
