import Layout from "../../layouts/Layout";
import { COMMON_LAYOUT } from "../../utils/constants";
import styles from "./Policy.module.scss";
import Title from "../../components/Title/Title";
import PrivacyPolicy from "./privacy-policy.html";
import CookiesPolicy from "./cookies-policy.html";

const policies = {
  PRIVACY: { content: PrivacyPolicy, title: "Privacy Policy" },
  COOKIES: { content: CookiesPolicy, title: "Cookies Policy" },
};

export default function Policy({ type }) {
  return (
    <Layout layout={COMMON_LAYOUT}>
      <div className="container-local">
        <div className={styles.container}>
          <Title className={styles.title} tag="h1">
            {policies[type].title}
          </Title>
          <div dangerouslySetInnerHTML={{ __html: policies[type].content }} />
        </div>
      </div>
    </Layout>
  );
}
