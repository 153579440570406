import styles from "./LoadingSpinner.module.scss";
import classnames from "classnames";

const LoadingSpinner = ({ size = 86, color = "#000", type = 1, className: classNameProp }) => {
  return type === 1 ? (
    <svg
      width={size}
      height={size}
      className={classnames(styles.svg, classNameProp)}
      viewBox="0 0 86 86"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={styles["spinner-path"]}
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        cx="43"
        cy="43"
        r="30"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles["spinner-path"]}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6 8C13.6 4.9072 11.0928 2.4 8 2.4C4.9072 2.4 2.4 4.90721 2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8ZM16 8C16 3.58172 12.4183 -5.4282e-07 8 -3.49691e-07C3.58172 -1.56562e-07 -5.4282e-07 3.58172 -3.49691e-07 8C-1.56562e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.4C11.0928 2.4 13.6 4.9072 13.6 8C13.6 11.0928 11.0928 13.6 8 13.6L8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 -5.4282e-07 8 -3.49691e-07L8 2.4Z"
        fill="white"
      />
      <path
        d="M8 16L8 13.6C5.64984 13.6 3.6378 12.1523 2.80706 10.1L0.581517 11C1.76829 13.9318 4.64262 16 8 16Z"
        fill="white"
      />
    </svg>
  );
};

export const LoadingOverlay = () => {
  return (
    <div className={styles["loading-overlay"]}>
      <LoadingSpinner />
    </div>
  );
};

export const LoadingSection = () => {
  return (
    <div className={styles["loading-section"]}>
      <LoadingSpinner />
    </div>
  );
};

export const FullscreenLoadingSpinner = () => {
  return (
    <div className={styles.fullscreen}>
      <LoadingSpinner />
    </div>
  );
};

export default LoadingSpinner;
