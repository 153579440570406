import styles from "./Hero.module.scss";
import t from "../../localizations.json";
import Button from "../Button/Button";

export default function Hero() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h1 className={styles.title}>{t.pages.home.title}</h1>
            <h5 className={styles.subtitle}>{t.pages.home.subtitle}</h5>
            <Button to="/our-process">{t.pages.home.button}</Button>
          </div>
        </div>
        <img src="/assets/images/circle-1.png" className={styles["circle-1"]} alt="circle-1" />
        <img src="/assets/images/circle-2.png" className={styles["circle-2"]} alt="circle-2" />
      </div>
    </div>
  );
}
