import styles from "./Hero.module.scss";
import Title from "../../../../components/Title/Title";
import t from "../../../../localizations.json";

export default function Hero() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Title className={styles.title} tag="h1">
            {t.pages.ourProcess.sections.hero.title}
          </Title>
          <div className={styles.paragraph}>
            <p dangerouslySetInnerHTML={{ __html: t.pages.ourProcess.sections.hero.paragraph1 }} />
            <p dangerouslySetInnerHTML={{ __html: t.pages.ourProcess.sections.hero.paragraph2 }} />
          </div>
        </div>
      </div>
      <img className={styles.image} src="./assets/images/our-process.png" alt="our-process.png" />
    </div>
  );
}
