import styles from "./Title.module.scss";
import classnames from "classnames";

export default function Title({ children, text, subtitle, className: classNameProp, tag = "h3" }) {
  const Tag = tag;
  return (
    <div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      <Tag className={classnames(styles.title, classNameProp)}>{text || children}</Tag>
    </div>
  );
}
