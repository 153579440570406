import styles from "./Benefits.module.scss";
import Title from "../../../../components/Title/Title";
import t from "../../../../localizations.json";

export default function Benefits() {
  return (
    <div className={styles.container}>
      <Title className={styles.title} subtitle="our">
        Benefits
      </Title>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles["col-title"]}>{t.pages.ourProcess.sections.benefits.col1.title}</div>
          <p className={styles["col-text"]}>
            <span dangerouslySetInnerHTML={{ __html: t.pages.ourProcess.sections.benefits.col1.text }} />
          </p>
        </div>
        <div className={styles.col}>
          <div className={styles["col-title"]}>{t.pages.ourProcess.sections.benefits.col2.title}</div>
          <p className={styles["col-text"]}>
            <span dangerouslySetInnerHTML={{ __html: t.pages.ourProcess.sections.benefits.col2.text }} />
          </p>
        </div>
        <div className={styles.col}>
          <div className={styles["col-title"]}>{t.pages.ourProcess.sections.benefits.col3.title}</div>
          <p className={styles["col-text"]}>
            <span dangerouslySetInnerHTML={{ __html: t.pages.ourProcess.sections.benefits.col3.text }} />
          </p>
        </div>
      </div>
    </div>
  );
}
