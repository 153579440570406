import styles from "./Box.module.scss";
import classnames from "classnames";

export default function Box({ icon, text, title, className: classNameProp, enableHtml = false }) {
  return (
    <div className={classnames(styles.wrapper, classNameProp)}>
      <div className={styles.icon}>
        <img src={`/assets/images/icons/${icon}.svg`} alt={`icon-${icon}`} />
      </div>
      <div className={classnames(styles.title, "title")}>{title}</div>
      <div className={styles.text}>{enableHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text}</div>
    </div>
  );
}
